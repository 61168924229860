// extracted by mini-css-extract-plugin
export var articleWrapper = "k_nj";
export var articleText = "k_nk d_dr d_cp";
export var header = "k_nl d_W d_s";
export var headerImageWrapper = "k_nm d_9 d_X d_5 d_4 d_1 d_6 d_bg d_bN";
export var headerGradient = "k_nn d_9 d_X d_5 d_4 d_1 d_6";
export var headerGradientOverlay = "k_np d_9 d_X d_5 d_4 d_1 d_6";
export var headerContentWrapper = "k_nq d_bw d_bL d_bz d_bF d_bh d_W";
export var contentWrapper = "k_mY d_s";
export var dateTag = "k_nr d_cb d_cn d_dm d_s";
export var icon1 = "k_ns d_cL";
export var icon2 = "k_nt d_cL d_cF";
export var tagBtn = "k_nv d_bY d_cz d_dl d_dx";
export var headerText = "k_nw d_s d_cb d_cp d_bS";
export var center = "k_nx d_ds";
export var videoIframeStyle = "k_ny d_d2 d_s d_D d_bv d_bY d_N";
export var articleImageWrapper = "k_nz d_cp d_W";
export var articleImageWrapperIcon = "k_nB d_cp d_W";
export var articleRow = "k_nC d_bz d_bb";
export var quoteWrapper = "k_gN d_by d_bL d_cp";
export var quoteBar = "k_nD d_D";
export var quoteText = "k_nF";
export var authorBox = "k_nG d_s";
export var authorRow = "k_nH d_bz d_bS d_cv";
export var separator = "k_nJ d_s";
export var line = "k_fg d_s d_fg d_cV d_cv";
export var authorImage = "k_lr d_b4 d_W d_s d_cq";
export var authorText = "k_nK d_cd d_cq";
export var masonryImageWrapper = "k_nL";
export var bottomSeparator = "k_nM d_s d_cv";
export var linksWrapper = "k_nN d_cd d_dr";
export var comments = "k_nP d_bz d_cv";
export var sharing = "k_nQ d_bw d_bz d_bF";
export var shareText = "k_nR d_s d_ds";
export var icon = "k_nS";
export var text = "k_nT";
export var SubtitleSmall = "k_nV";
export var SubtitleNormal = "k_nW";
export var SubtitleLarge = "k_nX";