// extracted by mini-css-extract-plugin
export var articleWrapper = "m_nj";
export var searchResult = "m_pY m_nj";
export var line = "m_fg d_fg d_cV";
export var articleTitle = "m_pZ d_cm";
export var articleText = "m_nk d_cp";
export var authorImage = "m_lr d_lr d_b4 d_W d_cq";
export var authorText = "m_nK d_cd d_cq";
export var textCenter = "m_ds d_ds";
export var searchWrapper = "m_p0 d_bw d_bL d_dn d_ch d_W";
export var searchInput = "m_p1 d_cB d_s d_b1";
export var searchBtn = "m_p2 d_bY d_by d_bL d_X d_cz d_dx";
export var text = "m_nT d_cn";
export var elementWrapper = "m_mW d_ch";
export var select = "m_p3 d_s d_b1";
export var articleImageWrapper = "m_nz d_cp d_W d_R";
export var articleImageWrapperIcon = "m_nB d_cp d_W d_R";
export var articleImageWrapperColumns = "m_p4 d_cp d_fd d_W";
export var contentPadding = "m_p5 d_cP";
export var otherPadding = "m_p6 d_cJ";
export var paginationWrapper = "m_p7";
export var pagination = "m_p8";
export var disabledPagination = "m_p9";
export var breakMe = "m_qb";
export var activePage = "m_qc";
export var next = "m_qd";
export var pages = "m_qf";
export var searchTitle = "m_qg d_cn";
export var categoryTitle = "m_qh d_cn";
export var searchText = "m_qj d_cq";
export var bold = "m_qk";
export var field = "m_ql";
export var dateTag = "m_nr d_h d_cn d_dm";
export var icon1 = "m_ns d_cL";
export var icon2 = "m_nt d_cL d_cF";
export var tagBtn = "m_nv d_bY d_cz d_dl d_dx";
export var linksWrapper = "m_nN d_cd d_ds";
export var resultImage = "m_qm";