// extracted by mini-css-extract-plugin
export var alignLeft = "t_qp d_fl d_bC d_dr";
export var alignCenter = "t_bL d_fm d_bz d_ds";
export var alignRight = "t_qs d_fn d_bD d_dt";
export var menuContainer = "t_rF d_dS";
export var menuContainerFull = "t_rG d_dQ";
export var menuMainHeader = "t_jz d_jz d_s d_c0";
export var menuComponentWrapper = "t_jD d_jD d_cr";
export var menuComponentWrapperDesign2 = "t_jF d_jF d_cv";
export var menuComponentText = "t_jB d_jB d_ck";
export var menuComponentTextDesign2 = "t_jC d_jC d_cp";
export var menuImageWrapperDesign2 = "t_jG d_jG d_s d_W";