// extracted by mini-css-extract-plugin
export var quoteParagraphLeft = "w_r2 d_gQ d_cs d_dr";
export var quoteParagraphCenter = "w_gR d_gR d_cs d_ds";
export var quoteParagraphRight = "w_gS d_gS d_cs d_dt";
export var quoteRowLeft = "w_r3 d_bC";
export var quoteRowCenter = "w_r4 d_bz";
export var quoteRowRight = "w_r5 d_bD";
export var quoteWrapper = "w_gN d_gN d_s d_cz d_bw d_bL d_bz";
export var quoteContentWrapper = "w_gP d_gP";
export var quoteExceptionSmall = "w_r6 y_r6";
export var quoteExceptionNormal = "w_r7 y_r7";
export var quoteExceptionLarge = "w_r8 y_r8";
export var quoteAuthorExceptionSmall = "w_r9 y_r9";
export var quoteAuthorExceptionNormal = "w_sb y_sb";
export var quoteAuthorExceptionLarge = "w_sc y_sc";