// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "x_sd d_bz";
export var storyRowWrapper = "x_hv d_hv d_bG";
export var storyLeftWrapper = "x_sf d_bw d_bL";
export var storyWrapperFull = "x_sg d_cz";
export var storyWrapperFullLeft = "x_pJ d_cz d_bw d_bL d_bz";
export var contentWrapper = "x_mY d_hw";
export var storyLeftWrapperCenter = "x_sh d_hx d_bw d_bz d_bL d_bF";
export var storyRightWrapperCenter = "x_sj d_hC";
export var storyHeader = "x_sk d_hB d_s d_cp";
export var storyHeaderCenter = "x_hz d_hz d_s d_cp d_ds d_bv";
export var storyParagraphCenter = "x_hy d_hy d_bv d_ds";
export var storyBtnWrapper = "x_sl d_dZ d_dY d_s d_bw d_bC";
export var storyBtnWrapperCenter = "x_hG d_hG d_dY d_s d_bw d_bz";
export var imageWrapper = "x_q8 d_fc d_W";
export var imageWrapperFull = "x_sm d_s d_D d_bb d_W";