// extracted by mini-css-extract-plugin
export var carouselContainer = "F_tQ d_s d_D d_bb d_W";
export var carouselContainerCards = "F_tR F_tQ d_s d_D d_bb d_W";
export var carouselContainerSides = "F_tS d_s d_D d_W";
export var prevCarouselItem = "F_tT d_s d_D d_X d_g";
export var prevCarouselItemL = "F_tV F_tT d_s d_D d_X d_g";
export var moveInFromLeft = "F_tW";
export var prevCarouselItemR = "F_tX F_tT d_s d_D d_X d_g";
export var moveInFromRight = "F_tY";
export var selectedCarouselItem = "F_tZ d_s d_D d_W d_bb";
export var selectedCarouselItemL = "F_t0 F_tZ d_s d_D d_W d_bb";
export var selectedCarouselItemR = "F_t1 F_tZ d_s d_D d_W d_bb";
export var nextCarouselItem = "F_t2 d_s d_D d_X d_g";
export var nextCarouselItemL = "F_t3 F_t2 d_s d_D d_X d_g";
export var nextCarouselItemR = "F_t4 F_t2 d_s d_D d_X d_g";
export var arrowContainer = "F_t5 d_D d_X d_dx d_bh d_1 d_bY";
export var prevArrowContainer = "F_t6 F_t5 d_D d_X d_dx d_bh d_1 d_bY";
export var prevArrowContainerHidden = "F_t7 F_t6 F_t5 d_D d_X d_dx d_bh d_1 d_bY d_g";
export var nextArrowContainer = "F_t8 F_t5 d_D d_X d_dx d_bh d_1 d_bY";
export var carouselContainerProducts = "F_t9";
export var nextArrowContainerHidden = "F_vb F_t8 F_t5 d_D d_X d_dx d_bh d_1 d_bY d_g";
export var arrow = "F_kK d_X";
export var prevArrow = "F_vc F_kK d_X";
export var nextArrow = "F_vd F_kK d_X";
export var carouselIndicatorContainer = "F_vf d_s d_ds d_bh d_bw d_bz d_bL d_bF d_X";
export var btnsContainer = "F_vg d_s d_bw d_bB";
export var carouselText = "F_vh d_dm d_bw d_bz d_bL d_bY d_dx d_cz";
export var carouselTextInactive = "F_vj F_vh d_dm d_bw d_bz d_bL d_bY d_dx d_cz";
export var indicator = "F_vk d_b4";
export var carouselIndicator = "F_vl F_vk d_b4";
export var carouselIndicatorSelected = "F_vm F_vk d_b4";
export var arrowsContainerTopRight = "F_vn d_X d_bh d_by";
export var arrowsContainerBottomLeft = "F_vp d_X d_bh d_by";
export var arrowsContainerSides = "F_vq d_X d_bh d_by";
export var smallArrowsBase = "F_vr d_bQ d_bw d_bz d_bL d_bY d_dx d_cz";
export var smallArrowContainer = "F_vs F_vr d_bQ d_bw d_bz d_bL d_bY d_dx d_cz";
export var smallArrowContainerPrev = "F_vt F_vs F_vr d_bQ d_bw d_bz d_bL d_bY d_dx d_cz";
export var smallArrowContainerSmall = "F_vv F_vr d_bQ d_bw d_bz d_bL d_bY d_dx d_cz";
export var smallArrowContainerPrevSmall = "F_vw F_vv F_vr d_bQ d_bw d_bz d_bL d_bY d_dx d_cz";
export var icon = "F_nS";
export var iconSmall = "F_vx";
export var multipleWrapper = "F_vy d_by d_bB d_bb";
export var multipleImage = "F_vz d_by";
export var sidesPrevContainer = "F_vB F_vs F_vr d_bQ d_bw d_bz d_bL d_bY d_dx d_cz d_fk d_X d_bh d_cz";
export var sidesNextContainer = "F_vC F_vs F_vr d_bQ d_bw d_bz d_bL d_bY d_dx d_cz d_fk d_X d_bh d_cz";