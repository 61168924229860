// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "s_rl d_gs d_cp";
export var heroHeaderCenter = "s_gt d_gt d_cp d_ds";
export var heroHeaderRight = "s_gv d_gv d_cp d_dt";
export var heroParagraphLeft = "s_rm d_gp d_cs";
export var heroParagraphCenter = "s_gq d_gq d_cs d_ds";
export var heroParagraphRight = "s_gr d_gr d_cs d_dt";
export var heroBtnWrapperLeft = "s_rn d_dZ d_dY d_s d_bw d_bC";
export var heroBtnWrapperCenter = "s_rp d_d0 d_dY d_s d_bw d_bz";
export var heroBtnWrapperRight = "s_rq d_d1 d_dY d_s d_bw d_bD";
export var overlayBtnWrapper = "s_rr d_gn d_X d_4 d_5 d_6 d_bk d_b8";
export var design4 = "s_rs d_gm d_X d_4 d_5 d_bk";
export var heroExceptionSmall = "s_rt y_rt";
export var heroExceptionNormal = "s_rv y_rv";
export var heroExceptionLarge = "s_rw y_rw";
export var Title1Small = "s_rx y_rx y_sn y_sp";
export var Title1Normal = "s_ry y_ry y_sn y_sq";
export var Title1Large = "s_rz y_rz y_sn y_sr";
export var BodySmall = "s_rB y_rB y_sn y_sH";
export var BodyNormal = "s_rC y_rC y_sn y_sJ";
export var BodyLarge = "s_rD y_rD y_sn y_sK";